<template>
  <div class="purchase-announcement left v-cloak" style="overflow: hidden;width: 100%;" v-cloak>
    <el-row style="height: auto;margin: 0px">
      <el-col class="announcement-title-img" :md="4" :lg="3" :sm="24">
        <div class="announcement-title">
          <img src="../../assets/image/pc/zhaobiao.png" style="width: 25px;height: 15px" alt="">
          招采信息
        </div>
      </el-col>
      <el-col class="announcement-title-p" :xs="21" :md="18" :lg="19" :sm="21">
        <div class="announcement-title-item left" @click="getItem(index,item)"
             v-for="(item,index) in notBuyerNotice.children" :key="index+'item'">
          <div class="announcement-title-span " :class="activeClass == index ? 'announcement-title-span-active':''">
            {{ item.categoryTitle }}
          </div>
        </div>
      </el-col>
      <el-col class="more cms-ssm-hidden" :sm="3" :xs="2" :md="2">
        <router-link class="span-color more-span"
                     :to="{name:'zonePurchase','query':{'companyId':headCompanyId,'id':demoId,'headLogo':headLogo}}">
          更多
        </router-link>
      </el-col>
    </el-row>
    <div style="display: block" class="row no-gutters tender-content">
      <div class="announcement-content-items left col-sm-6 col-md-6 col-lg-4 col-xl-4"
           v-for="(announcement,i) in notBuyerNoticeList" :key="'keys_'+i">
        <router-link v-if="announcement.purchaseMode != '-1'"
                     :to="{name:'zonePurchaseDetails','query':{'contentId': announcement.contentId, 'type': announcement.type,'companyId':headCompanyId,'id':demoId,'formType':announcement.formType,vc:announcement.vc}}">
          <div class="announcement-content-item">
            <div
                class="announcement-notice-title cms-xl-show-one-line cms-lg-show-one-line cms-md-show-one-line cms-sm-show-one-line"
                :title="announcement.title">
              {{ announcement.title }}
            </div>
            <div class="announcement-company show-one-line cms-ssm-show-two-line">
              <span v-if="announcement.tendereeName != announcement.companyName && announcement.tendereeName !=undefined && announcement.tendereeName !=null &&announcement.tendereeName !='' ">委托方：</span>
              <span :title="announcement.tendereeName">{{ (announcement.tendereeName != undefined && announcement.tendereeName != null && announcement.tendereeName !='') ? announcement.tendereeName :announcement.companyName  }}</span>
            </div>
            <div class="announcement-type">
              <label>{{ getAnnouncementType(announcement.purchaseMode) }}</label>
            </div>
            <div class="announcement-open-time">
                <label v-if="activeClass=='0'">开标时间:{{ formatDate(announcement.bidOpenTime, 'YYYY/MM/DD HH:mm:ss') }} </label>
                <label v-else>发布时间: {{ formatDate(announcement.releaseTime, 'YYYY/MM/DD HH:mm:ss') }} </label>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="cms-box cms-md-hidden cms-lg-hidden cms-xl-hidden cms-sm-hidden"
         style="display:flex;justify-content: center;align-items: center;width: 100%">
      <el-pagination :current-page="currentPage" :page-size="pageSize" :total="total" :pager-count="5"
                     @current-change="handleCurrentChange" background layout="prev, pager, next">
      </el-pagination>
    </div>
  </div>

</template>

<script>
import moment from 'moment'

export default {
  name: "specialized-new-zone-announcement",
  props: ["companyId", "id", "headLogo", "company", "queryType"],
  data() {
    return {
      /*非招标公告*/
      moment,
      headCompanyId: ""
      , demoId: ""
      , demoHeadLogo: "",
      total: 0,
      currentPage: 0,
      pageSize: 6,
      notBuyerNotice: {},
      notBuyerNoticeList: [],
      query: {},
      activeClass: 0,
      isBid: false,
    }
  }, beforeCreate() {
  }, created() {
    this.headCompanyId = this.$route.query.companyId;
    this.demoId =  this.$route.query.id;
    this.demoHeadLogo = this.headLogo;
    this.selectNotice("recruit_notice", "notBuyerNotice", "notBuyerNoticeList", 0);
  }, methods: {
    /*查询公告栏目*/
    selectNotice: function (modelId, entityName, beanName, index) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        let type = data.data.result.children[index].noticeType;
        let purchaseMode = data.data.result.children[index].purchaseMode;
        that.getNoticeList(type, purchaseMode, beanName);
      }).catch(function (error) {

      })
    },
    /*查询公告列表*/
    getNoticeList(type, purchaseMode, beanName) {
      let that = this;
      that.query.type = type;
      if (that.company.companyId == '2706' || that.company.companyId == '5246' || that.company.companyId == '5245') {
        //中小服务平台的显示所有的
        that.query.companyName="";
      } else {
        that.query.companyName = this.$route.query.id;
        // //其他的显示该公司自己的
        // if (that.company.companyId == undefined || that.company.companyId == null || that.company.companyId == '') {
        //   that.query.companyName = that.company.companyId;
        // } else {
        // }
      }
      if ((that.company.companyId != "" && that.company.companyId != "0" && that.company.companyId != null) || that.queryType == 'all') {
        that.$http.post(that.base + "/bulletin/getRecruit.do", {
          page: {
            current: that.currentPage,
            size: that.pageSize
          },
          cmsBulletinDTO: that.query
        }).then(function (data) {
          that[beanName] = data.data.result.records;
          that.total = data.data.result.total;
        }).catch(function (error) {
        })
      }
    },
    formatDate: function (de, fmt) {
      return moment(de).format(fmt);
    }
    ,
    handleCurrentChange: function (val) {
      this.currentPage = val;
      if (this.isBid) {
        this.selectBiddingList("notBuyerNoticeList");
      } else {
        this.getNoticeList(this.query.type, this.query.purchaseMode, "notBuyerNoticeList");
      }
    },
    getItem: function (index, item) {
      this.activeClass = index;
      this.currentPage = 0;
      this.notBuyerNoticeList = [];
      if (index == item.length) {
        this.isBid = true;
        this.selectBiddingList("notBuyerNoticeList");
      } else {
        this.getNoticeList(item.noticeType, item.purchaseMode, "notBuyerNoticeList")
      }
    },
    //初始化竞价交易
    selectBiddingList: function (beName) {
      var that = this;
      that.query.linkUrl = "https://client.holdsteel.com/gdpaas/comp/getBidOrderPage.htm";
      if (that.company.title != null && that.company.title != '') {
        if (that.company.title.indexOf('涟源') != -1) {
          that.query.companyId = "涟源";
        } else if (that.company.title.indexOf('安赛乐米塔尔') != -1 || that.company.title.indexOf('VAMA') != -1) {
          that.query.companyId = "涟源";
        } else {
          that.query.companyId = null;
        }
      }
      if (that.query.companyId != null && that.query.companyId != '') {
        this.$http.post(this.base + "/hgw/getBiddingInfo.do", {
          page: {
            current: that.currentPage,
            size: 4,
          },
          cmsPromotionDTO: that.query
        }).then((data) => {
          // 新接口
          let dataRes = JSON.parse(data.data.result);
          that.total = dataRes.totalCount;
          that[beName] = [];
          dataRes.result.some((item) => {
            let date = {
              "title": item.BIDNAME,
              "companyName": item.SELLERMBRNAME,
              "purchaseMode": '-1',
              "bidOpenTime": item.BIDDATEBTW,
              "code": item.BIDNO
            }
            that[beName].push(date);
          })
        }).catch(function (error) {

        })
      }
    },
    getAnnouncementType(purchaseMode) {
      let announcementType = "";
      switch (purchaseMode) {
        case "186":
          announcementType = "议价";
          break;
        case "-1":
          announcementType = "竞价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
        case "10":
          announcementType = "招标";
          break;
        case "210":
          announcementType = "招标";
          break;
        case "20":
          announcementType = "邀请招标";
          break;
        case "186":
          announcementType = "议价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
      }
      return announcementType;
    },

  }
}
</script>

<style>
/*分页背景颜色*/
.cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #D0021B;
}
</style>
<style scoped>
.cms-box {
  margin-top: 60px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
  padding: 10px;
}

[v-cloak] {
  display: none
}

.more-span {
  cursor: pointer;
  color: #999999;
  border-radius: 10px;
  background: #F5F5F5;
  padding: 5px 10px 5px 10px;
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  font-size: 1.2rem;
  color: #999999;
}

.loading {
  display: none
}

.loading[v-cloak] {
  display: block
}

.purchase-announcement {
  background: white;
  margin: 0 auto;
  height: 100%;
}

.hide {
  display: none;
}

.announcement-title {
  font-size: 1.7rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 45px;
  padding-left: 22px;
}

.announcement-title-span {
  padding: 10px 10px 5px 10px;
  font-size: 1.6rem;
  margin-top: 20px;
}

.announcement-title-span:hover {
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 2px solid red;
}

.announcement-title-span-active {
  border-bottom: 2px solid red;
}


.announcement-title-img {
  height: 42px;
  margin-top: 20px;
  padding-left: 0px !important;
}

.announcement-content-item {
  font-size: 1.4rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #111111;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #E5E5E5;
  padding: 10px 20px 10px 20px;
}

.el-tabs__nav {
  white-space: pre-line !important;
}

.announcement-company {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  margin-bottom: 8px;
}

.announcement-type {
  /*margin-bottom: 10px;*/
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
}

.announcement-title-img img {
  width: 100%;
}

.announcement-type label {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 600;
  background: #D4000A;
  color: white;
  border-radius: 10px;
  padding: 2px 10px 2px 10px;
}

.announcement-open-time {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 18px;
  color: #9E4646;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}

.announcement-notice-title {
  margin-bottom: 10px;
}

@media screen and (max-width: 572px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n) {
    padding-right: 15px;
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n+1) {
    padding-right: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n+1) {
    padding-left: 15px !important;
  }

  .announcement-title-img img {
    /*min-width: 120px;*/
  }

  [v-cloak] {
    display: none
  }

  .more {
    padding-top: 12px;
  }

  .purchase-announcement {
    background: white;
    height: 100%;
    margin-bottom: 10px;
    /*margin-top: 10px;*/
  }

  .announcement-title-span {
    padding: 5px 5px 5px 5px;
    font-size: 1.5rem;
    margin-top: 5px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }


  .announcement-title-img {
    height: 42px;
    margin-top: 0px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    background: #D4000A;
    color: white;
    border-radius: 10px;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9E4646;
    line-height: 19px;
  }

  .announcement-title-p {
    padding-left: 22px;
  }

  .cms-box {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n+1) {
    padding-left: 15px !important;
  }

  .cms-box {
    margin-top: 15px;
  }

  .announcement-title {
    font-size: 1.5rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  .announcement-title-p {
    margin-top: 10px;
  }

  .more {
    padding-top: 10px;
  }

  [v-cloak] {
    display: none
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
  }

  .announcement-title-span {
    padding: 5px 5px 5px 5px;
    font-size: 1.5rem;
    margin-top: 20px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }


  .announcement-title-img {
    height: 42px;
    margin-top: 10px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;
  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    background: #D4000A;
    color: white;
    border-radius: 10px;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9E4646;
    line-height: 19px;
  }

  .announcement-title-p {
    margin-top: -15px;
    padding-left: 22px;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    /*min-height: 605px;*/
    margin-bottom: 10px;
  }

  .tender-content {
    min-height: 460px;
    margin-bottom: 10px;
  }

  .tender-content .announcement-content-items:nth-child(2n) {
    padding-right: 15px !important;
  }

  .tender-content .announcement-content-items:nth-child(2n+1) {
    padding-left: 15px !important;
  }
}

.announcement-title-img img {
  max-width: 150px;
}


@media (min-width: 992px) and (max-width: 1200px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n+1) {
    padding-left: 15px !important;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 380px;
    margin-bottom: 20px;
  }

  .tender-content {
    /*min-height: 380px;*/
  }

  .cms-box {
    margin-top: 5px;
  }

  .announcement-title {
    font-size: 1.6rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  [v-cloak] {
    display: none
  }

  .purchase-announcement {
    background: white;
    height: 100%;
    margin-bottom: 15px;
  }


  .announcement-title-span {
    padding: 10px 5px 5px 10px;
    font-size: 1.5rem;
    margin-top: 20px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }

  .announcement-title-span-active {
    border-bottom: 2px solid red;
  }


  .announcement-title-img {
    /*width: 130px;*/
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .el-tabs__nav {
    white-space: pre-line !important;
  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    border-radius: 10px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    background: #D4000A;
    color: white;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9E4646;

    line-height: 19px;
  }

  .more {
    padding-top: 32px !important;
  }
}

@media (min-width: 1200px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n+1) {
    padding-left: 15px !important;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 375px;
    margin-bottom: 20px;
  }

  .tender-content {
    min-height: 300px;
  }
}

@media screen and (max-width: 768px){
  .announcement-title-span-active {
    font-weight: bold;
    border: none !important;
    color: red !important;
  }
  .announcement-title-span:hover{
    border: none !important;
    color: red !important;
  }
}

</style>

