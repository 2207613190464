<template>
  <div id="shop" @click="goShopping">
<!--    <div class="shop-bg shop-all"/>-->
    <div class="shop-title ">
      <img src="../../assets/image/pc/zhaobiao.png" style="width: 25px;height: 15px" alt="">
      <span v-if="companyId == 2880">湖南省国资云直采商城</span>
      <span v-else-if="companyId == 5246">长沙经开区直采商城</span>
      <span v-else-if="companyId == 5245">湘江新区直采商城</span>
      <span v-else>直采商城</span>
    </div>
    <div class="shop-content shop-all">
      <img src="../../assets/image/pc/shop-details.png" style="width: 100%;height:auto" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "specialized-new-zone-shop",
  data() {
    return {
      companyId:this.$route.query.id
    }
  },methods:{
    goShopping:function () {
      let companyId = this.$route.query.id;
      if ('2' == companyId) {
        window.open("https://bgsl.holdcg.com");
      }else if ("2880"  == companyId){
        window.open("https://hngzymall.holdcg.com/#/coverPage");
      }else if ("2725"  == companyId){
        window.open("https://xsjtmall.holdcg.com/#/mall");
      }else {
        window.open("https://mall.holdcg.com");
      }
    }
  }
}
</script>

<style scoped>
#shop{
  margin-bottom: 20px;
  cursor: pointer;
}

.shop-bg {
  background-image: url("../../assets/image/pc/shop-bg.png");
  height: 260px;
}
.shop-all{
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media (min-width: 1200px) {
  #shop {
    background: white;
  }

  .shop-content {
    width: 100%;
  }

  .shop-title {
    width: 100%;
    background: #FFFFFF;
    font-size: 1.7rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    line-height: 60px;
    padding-left: 22px;
  }

}

@media (min-width: 992px) and (max-width: 1200px) {
  #shop {
    margin-bottom: 20px;
    background: white;
  }

  .shop-content {
    width: 100%;
  }

  .shop-bg {
    height: 220px;
  }

  .shop-title {
    width: 100%;
    font-size: 1.7rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    line-height: 45px;
    padding-left: 22px;
  }

}


@media (min-width: 768px) and (max-width: 991px) {
  #shop {
    background: white;
    margin-bottom: 10px;
  }

  .shop-content {
    width: 100%;
  }

  .shop-bg {
    height: 180px;
  }
  .shop-title {
    width: 100%;
    font-size: 1.7rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    line-height: 45px;
    padding-left: 22px;
  }
}
</style>
