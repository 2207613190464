<template>
  <div id="specialized-new-zone-solution">
    <div class="left solution-title ">
      <div>
        <img src="../../assets/image/pc/zhaobiao.png" style="width: 25px;height: 15px" alt="">
        解决方案
      </div>
    </div>
    <el-row :gutter="10">
      <el-col class="solution-col" v-for="(item,i) in solutionList" :key="'solution'+i" :xs="12" :sm="8">
        <router-link :to="{'name':'solution',query:{'current':i}}">
          <div v-for="(item2 ,index) in item.imagesUrl" :key="'solutionUrls'+index">
            <img :src="item2.url" class="w-100 h-100" alt="">
          </div>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "specialized-new-zone-solution",
  data() {
    return {
      solution: {},
      solutionList: [],
    }
  },
  methods: {
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      axios.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        axios.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
            if (data.data.result[i].companyId == this.headId) {
              data.data.result[i].bgUrl = JSON.parse(data.data.result[i].bgUrl);
              that.carousels.push(data.data.result[i]);
            }
          }
          if (this.headId == "") {
            let ca = {
              "id": "",
              "title": "",
              "imagesUrl": "",
              "categoryId": "",
              "url": "",
              "linkText": "",
              "createdTime": "",
              "updateTime": null,
              "userId": "0",
              "companyId": "",
              "companyName": null,
              "isDeleted": 0,
              "isIssue": 0,
              "sortNo": 0,
              "groupId": null,
              "intervalId": null,
              "keyword": "",
              "description": "",
              "registeredCapital": "",
              "industry": "",
              "businessScope": "",
              "logoUrl": "\"\"",
              "bgUrl": [{
                "path": "",
                "url": "http://old2.holdcg.com/group1/M00/02/69/CgA7PmIXTXeAc_bIAAbzXT1G2Xo783.png",
                "uid": "",
                "size": "66.15",
                "status": "success"
              }],
              "contactText": "",
              "isRecommend": 0,
              "linkUrl": ""
            };
            that.carousels.push(ca);
          }

          that[entityListName] = data.data.result;
        })
      })
    },
  }, created() {
    this.selectPromotionList("solution", "solution", "solutionList");
  }
}
</script>

<style scoped>
#specialized-new-zone-solution {
  background: white;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.solution-col {
  margin-bottom: 5px;
}

.el-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.solution-title {
  font-size: 1.7rem;
  width: 100%;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 60px;
  padding-left: 22px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  #specialized-new-zone-solution {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 991px) {
  #specialized-new-zone-solution {
    margin-bottom: 10px;
  }
}
</style>
