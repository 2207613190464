<template>
  <div class="purchase-announcement left v-cloak" v-cloak>
    <div>
      <el-row style="height: auto;margin: 0px">
        <el-col class="announcement-title-img" :md="22" :xs="21" :sm="21">
          <div class="announcement-title">
            <img src="../../assets/image/pc/zhaobiao.png" style="width: 25px;height: 15px" alt="">
            竞价信息
          </div>
        </el-col>
        <el-col class="more cms-ssm-hidden" :sm="3" :xs="3" :md="2">
          <a target="_blank" href="https://client.holdsteel.com/marketpt/bidsaloon/items/index.htm" class="more-span">
            更多
          </a>
        </el-col>
      </el-row>
      <div style="display: block" class="row no-gutters tender-content">
        <div class="announcement-content-items left col-sm-6 col-md-6 col-lg-4 col-xl-4"
             v-for="(announcement,i) in bidNoticeList" :key="'keys_'+i">
          <a target="_blank"
             :href="'https://client.holdsteel.com/marketpt/buybidding/items/bidNotice.htm?boutCode='+announcement.code">
            <div class="announcement-content-item">
              <div
                  class="announcement-notice-title cms-xl-show-one-line cms-lg-show-one-line cms-md-show-one-line cms-sm-show-one-line"
                  :title="announcement.title">
                {{ announcement.title }}
              </div>
              <div class="announcement-code">
                场次编码：{{ announcement.code }}
              </div>
              <div class="announcement-company show-one-line cms-sm-show-two-line">
                {{ announcement.companyName }}
              </div>
              <div class="announcement-type">
                <label>{{ getAnnouncementType(announcement.purchaseMode) }}</label>
              </div>
              <div class="announcement-open-time">
                <label> {{ announcement.bidOpenTime }}</label>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="cms-box cms-md-hidden cms-lg-hidden cms-xl-hidden cms-sm-hidden"
           style="display:flex;justify-content: center;align-items: center;width: 100%">
        <el-pagination :current-page="bidCurrentPage" :page-size="bidPageSize" :total="bidTotal" :pager-count="5"
                       @current-change="handleBidCurrentChange" background layout="prev, pager, next">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'

export default {
  name: "specialized-new-zone-bid-announcement",
  props: ["companyId", "id", "headLogo", "company"],
  data() {
    return {
      /*非招标公告*/
      moment,
      headCompanyId: ""
      , demoId: ""
      , demoHeadLogo: "",
      total: 0,
      bidTotal: 0,
      currentPage: 0,
      bidCurrentPage: 0,
      pageSize: 6,
      bidPageSize: 6,
      notBuyerNotice: {},
      notBuyerNoticeList: [],
      query: {},
      activeClass: 0,
      isBid: false,
      bidNoticeList: []
    }
  }, beforeCreate() {
  }, created() {
    this.selectBiddingList("bidNoticeList");
  }, methods: {
    handleBidCurrentChange: function (val) {
      this.bidCurrentPage = val;
      this.selectBiddingList("bidNoticeList");
    },
    getItem: function (index, item) {
      this.activeClass = index;
      this.currentPage = 0;
      this.notBuyerNoticeList = [];
      if (index == item.length) {
        this.isBid = true;
        this.selectBiddingList("bidNoticeList");
      } else {
        this.getNoticeList(item.noticeType, item.purchaseMode, "notBuyerNoticeList")
      }
    },
    //初始化竞价交易
    selectBiddingList: function (beName) {
      var that = this;
      that.query.linkUrl = "https://client.holdsteel.com/gdpaas/comp/getBidOrderPage.htm";
      this.$http.post(this.base + "/hgw/getBiddingInfo.do", {
        page: {
          current: that.bidCurrentPage,
          size: that.bidPageSize,
        },
        cmsPromotionDTO: that.query
      }).then((data) => {
        // 新接口
        let dataRes = JSON.parse(data.data.result);
        that.bidTotal = dataRes.totalCount;
        that[beName] = [];
        dataRes.result.some((item) => {
          let date = {
            "title": item.BIDNAME,
            "companyName": item.SELLERMBRNAME,
            "purchaseMode": '-1',
            "bidOpenTime": item.BIDDATEBTW,
            "code": item.BIDNO
          }
          that[beName].push(date);
        })
      }).catch(function (error) {
      })
    },
    getAnnouncementType(purchaseMode) {
      let announcementType = "";
      switch (purchaseMode) {
        case "186":
          announcementType = "议价";
          break;
        case "-1":
          announcementType = "竞价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
        case "10":
          announcementType = "公开招标";
          break;
        case "210":
          announcementType = "招标";
          break;
        case "20":
          announcementType = "邀请招标";
          break;
        case "186":
          announcementType = "议价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
      }
      return announcementType;
    },

  }
}
</script>

<style>
/*分页背景颜色*/
.cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #D0021B;
}
</style>
<style scoped>
.cms-box {
  margin-top: 60px;
}

[v-cloak] {
  display: none
}

.announcement-code {
  font-size: 1.4rem;
  font-weight: 500;
  color: #111111;
  line-height: 19px;
  margin-bottom: 5px;
}

.more-span {
  cursor: pointer;
  color: #999999;
  border-radius: 10px;
  background: #F5F5F5;
  padding: 5px 10px 5px 10px;
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  font-size: 1.2rem;
  color: #999999;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
  padding: 10px;
}

.announcement-title {
  font-size: 1.7rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 45px;
  padding-left: 22px;
}


.announcement-title-img {
  height: 42px;
  margin-top: 20px;
  padding-left: 0px !important;
}

.announcement-content-item {
  font-size: 1.4rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #111111;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #E5E5E5;
  padding: 10px 20px 10px 20px;
}


.announcement-content-items {
  background: #FFFFFF;
  margin-bottom: 20px;
}

.announcement-company {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  margin-bottom: 8px;
}

.announcement-type {
  /*margin-bottom: 10px;*/
}

.announcement-title-img img {
  width: 100%;
}

.announcement-type label {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  background: #D4000A;
  color: white;
  border-radius: 10px;
  padding: 2px 10px 2px 10px;
}

.announcement-open-time {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 19px;
  color: #9E4646;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}

.announcement-notice-title {
  margin-bottom: 5px;
}

@media screen and (max-width: 572px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n) {
    padding-right: 15px;
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n+1) {
    padding-right: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n+1) {
    padding-left: 15px !important;
  }

  .announcement-title-img img {
    /*min-width: 120px;*/
  }

  [v-cloak] {
    display: none
  }

  .more {
    padding-top: 10px;
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
    margin-bottom: 10px;
  }

  .announcement-title-img {
    height: 42px;
    margin-top: 0px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    background: #D4000A;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9E4646;

    line-height: 19px;
  }

  .announcement-title-p {
    padding-left: 22px;
  }

  .cms-box {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cms-box {
    margin-top: 15px;
  }

  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(2n+1) {
    padding-left: 15px !important;
  }

  .announcement-title {
    font-size: 1.5rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  .announcement-title-p {
    margin-top: 10px;
  }

  .more {
    padding-top: 25px;
  }

  [v-cloak] {
    display: none
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
  }

  .announcement-title-img {
    /*width: 130px;*/
    height: 42px;
    margin-top: 10px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 5px 20px;
  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 20px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 10px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    background: #D4000A;
    color: white;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9E4646;

    line-height: 19px;
  }

  .announcement-title-p {
    margin-top: -15px;
    padding-left: 22px;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 560px;
    margin-bottom: 10px;
  }

  .tender-content {
    min-height: 480px;
    padding: 10px;
  }
}

.announcement-title-img img {
  max-width: 150px;
}


@media (min-width: 992px) and (max-width: 1200px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n+1) {
    padding-left: 15px !important;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 405px;
  }

  .tender-content {
    min-height: 390px;
    padding: 10px;
  }

  .cms-box {
    margin-top: 5px;
  }

  .announcement-title {
    font-size: 1.6rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  [v-cloak] {
    display: none
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
    margin-bottom: 15px;
  }


  .announcement-title-img {
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }


  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 20px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 10px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    background: #D4000A;
    color: white;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #9E4646;

    line-height: 19px;
  }

  .more {
    padding-top: 32px !important;
  }
}

@media (min-width: 1200px) {
  .announcement-content-items {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n) {
    padding-right: 15px;
  }

  .tender-content .announcement-content-items:nth-child(3n+1) {
    padding-left: 15px !important;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 410px;
    background: white;
    /*margin-top: 20px;*/
    margin-bottom: 20px;
  }

  .tender-content {
    min-height: 310px;
  }
}
</style>



