<template>
  <div class="cms-container left" id="specialized-new-zone-advertise">
    <el-row class="specialized-advertise">
      <el-col class="advertise-item" v-for="(item,i) in servicesList" :key="'solution'+i" :xs="12" :sm="12">
        <div @click="openUrl(item.linkText)" v-for="(item2 ,index) in item.imagesUrl" :key="'solutionUrls'+index">
          <img :src="item2.url" class="w-100 h-100" alt="">
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "specialized-new-zone-advertise",
  data() {
    return {
      service: {},
      servicesList: []
    }
  }, methods: {
    openUrl:function (url) {
      window.open(url);
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      axios.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        axios.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
            if (data.data.result[i].companyId == this.headId) {
              data.data.result[i].bgUrl = JSON.parse(data.data.result[i].bgUrl);
              that.carousels.push(data.data.result[i]);
            }
          }
          if (this.headId == "") {
            let ca = {
              "id": "",
              "title": "",
              "imagesUrl": "",
              "categoryId": "",
              "url": "",
              "linkText": "",
              "createdTime": "",
              "updateTime": null,
              "userId": "0",
              "companyId": "",
              "companyName": null,
              "isDeleted": 0,
              "isIssue": 0,
              "sortNo": 0,
              "groupId": null,
              "intervalId": null,
              "keyword": "",
              "description": "",
              "registeredCapital": "",
              "industry": "",
              "businessScope": "",
              "logoUrl": "\"\"",
              "bgUrl": [{
                "path": "",
                "url": "http://old2.holdcg.com/group1/M00/02/69/CgA7PmIXTXeAc_bIAAbzXT1G2Xo783.png",
                "uid": "",
                "size": "66.15",
                "status": "success"
              }],
              "contactText": "",
              "isRecommend": 0,
              "linkUrl": ""
            };
            that.carousels.push(ca);
          }

          that[entityListName] = data.data.result;
        })
      })
    },
  }, created() {
    this.selectPromotionList("added-services", "service", "servicesList");
  }
}
</script>

<style scoped>
#specialized-new-zone-advertise {
  margin-bottom: 20px;
  /*margin-top: 20px;*/
}
.specialized-advertise{
  display: flex;
  gap: 20px;
}
@media  (min-width: 992px) and (max-width: 1200px){
  #specialized-new-zone-advertise {
    margin-bottom: 15px!important;
    /*margin-top: 20px;*/
  }
}
@media (min-width: 992px) {
  .advertise-item {
    height: 120px;
  }

  #specialized-new-zone-advertise {
    margin-bottom: 20px;
    /*margin-top: 20px;*/
  }

  .advertise-item > div {
    height: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  /*.advertise-item {
    height: 80px;
  }*/
  #specialized-new-zone-advertise {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .advertise-item {
    width: 50%;
  }

  .advertise-item > div {
    height: 100% !important;
  }

  #specialized-new-zone-advertise {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .advertise-item {
    height: 100px;
    width: 50%;
  }

  .advertise-item > div {
    height: 100% !important;
  }

  #specialized-new-zone-advertise {
    margin-bottom: 10px;
    /*margin-top: 10px;*/
  }
}

</style>
