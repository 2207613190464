import { render, staticRenderFns } from "./specialized-new-zone-announcement.vue?vue&type=template&id=30f211cc&scoped=true"
import script from "./specialized-new-zone-announcement.vue?vue&type=script&lang=js"
export * from "./specialized-new-zone-announcement.vue?vue&type=script&lang=js"
import style0 from "./specialized-new-zone-announcement.vue?vue&type=style&index=0&id=30f211cc&prod&lang=css"
import style1 from "./specialized-new-zone-announcement.vue?vue&type=style&index=1&id=30f211cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f211cc",
  null
  
)

export default component.exports